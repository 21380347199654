<template>
    <div class="teacherListBox">
        <div class="headBox">
            <el-form :inline="true" style="float: right">
                <el-form-item>
                    <div style="display: flex;background: white;align-items: center">
                        <el-input v-model="params.key" placeholder="输入名字" style="width:3rem"
                                  @keyup.enter.native="initData"></el-input>
                        <i class="el-icon-search" @click="initData"
                           style="font-size: 0.28rem;color:rgba(86, 74, 240, 1);margin-right:0.08rem;cursor: pointer"></i>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="background:rgba(86, 74, 240, 1)" @click="open()">+ 新增老师</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="contentBox" v-if="list.length>0">
            <el-table
                    :center="true"
                    :data="list"
                    style="width: 100%;margin: auto;border-radius:8px">
                <el-table-column
                        width="80px"
                        prop="title"
                        label="头像">
                    <template slot-scope="scope">
                        <img style="width: 0.44rem;height: 0.44rem;border-radius:50%" :src="scope.row.img" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                        width="180px"
                        prop="name"
                        label="名称">
                </el-table-column>
                <el-table-column
                        prop="tag"
                        label="标签">
                    <template slot-scope="scope">
                        <div class="cardBox">
                            <div v-for="(item,index) in scope.row.tag.split(',')" :key="index">
                                {{item}}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="140px">
                    <template slot-scope="scope">
                        <el-button type="text" style="color:rgba(86, 74, 240, 1)" @click="open(scope.row)">修改
                        </el-button>
                        <el-button type="text" style="color: rgba(244, 102, 0, 1);margin-right:0.20rem;font-size:0.12rem" @click="delInfo(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="width: 10rem;text-align: right;margin-top:0.12rem">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :current-page.sync="params.page"
                        :total="total"
                        :page-size="params.per_page"
                        v-if="total"
                ></el-pagination>
                <!--                <el-pagination-->
                <!--                        @size-change="handleSizeChange"-->
                <!--                        @current-change="handleCurrentChange"-->
                <!--                        :current-page="params.page"-->
                <!--                        :page-sizes="[10, 20, 30, 40, 50]"-->
                <!--                        :page-size="10"-->
                <!--                        layout="total, sizes, prev, pager, next, jumper"-->
                <!--                        :total="total">-->
                <!--                </el-pagination>-->
            </div>
        </div>
        <div v-else>
            <el-empty description="没有内容哦"></el-empty>
        </div>
        <!-- 添加，修改教师弹窗-->
        <el-dialog
                @close="close"
                :visible.sync="openDialog"
                width="50%">
            <div slot="title"
                 style="padding-bottom: 0.22rem;border-bottom:1px solid rgba(119,128,125,0.29);color: rgba(46, 48, 52, 1);font-size:0.18rem">
                {{ type == 1 ? '新增老师' : '编辑老师' }}
            </div>
            <el-form label-width="80px" :model="form" ref="form" :rules="rules">
                <div style="width:100%;display: flex">
                    <div class="left">
                        <el-form-item label="头像" style="margin-bottom: 0.4rem;" class="img" prop="img">
                            <el-upload
                                    accept="image/png,image/jpg,image/jpeg"
                                    :class="{ imgHide1: imgHideUpload1 }"
                                    class="avatar-uploader1"
                                    :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + params.token"
                                    list-type="picture-card"
                                    :show-file-list="false"
                                    :auto-upload="true"
                                    :on-remove="removeImgs1"
                                    :on-success="handleAvatarSuccess1"
                            >
                                <img v-if="form.img" :src="form.img" class="avatar1"/>
                                <i class="el-icon-plus" v-else></i>
                            </el-upload>
                            <p class="fblBox">建议像素160*160，分辨率100+</p>
                        </el-form-item>

                        <el-form-item label="形象照" class="photo">
                            <el-upload
                                    style="position:relative"
                                    accept="image/png,image/jpg,image/jpeg"
                                    :class="{ imgHide2: imgHideUpload2 }"
                                    class="avatar-uploader2"
                                    :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + params.token"
                                    list-type="picture-card"
                                    :show-file-list="false"
                                    :auto-upload="true"
                                    :on-remove="removeImgs2"
                                    :on-success="handleAvatarSuccess2"
                            >

                                <img v-if="form.photo" :src="form.photo" class="avatar2"/>
                                <i class="el-icon-plus" v-else></i>

                            </el-upload>
                            <i class="el-icon-close" @click="clearPhoto"></i>
                            <p class="fblBox">建议像素320*405，分辨率100+</p>
                        </el-form-item>
                    </div>
                    <div class="right">
                        <el-form-item label="老师名称" prop="name">
                            <el-input
                                    placeholder=""
                                    v-model="form.name"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="老师标签" prop="tag">
                            <div class="tagBox">
                                <el-tag
                                        :key="tag"
                                        v-for="tag in form.tag"
                                        closable
                                        :disable-transitions="false"
                                        @close="handleClose(tag)">
                                    {{tag}}
                                </el-tag>
                                <el-input
                                        class="input-new-tag"
                                        v-if="inputVisible"
                                        v-model="inputValue"
                                        ref="saveTagInput"
                                        size="small"
                                        @keyup.enter.native="handleInputConfirm"
                                        @blur="handleInputConfirm"
                                >
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag
                                </el-button>
                            </div>
                        </el-form-item>
                        <el-form-item label="老师简历" prop="introduce">
                            <el-input
                                    :maxlength="200"
                                    :rows="8"
                                    type="textarea"
                                    placeholder=""
                                    v-model="form.introduce"
                                    :show-word-limit="true"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <span slot="footer" v-if="type ==1">
                <el-button type="info" size="large" style="background: rgba(244, 102, 0, 1);color:white" @click="submit" v-if="form.introduce && form.tag.length>0 && form.img && form.name">确 定</el-button>
                <el-button type="info" size="large" @click="submit" v-else>确 定</el-button>
            </span>
            <span slot="footer" v-if="type ==2">

                <el-button type="info" size="large" style="background: rgba(244, 102, 0, 1);color:white" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getToken} from "../../utils/auth";
    import {getTeacherList, addTeacher, editTeacher,delTeacher} from "../../Api";
    import {mapGetters} from "vuex";

    export default {
        name: "TeacherListIndex",
        data() {
            return {
                rules: {
                    img: [
                        {required: true, message: "", trigger: "blur"},
                    ],
                    name: [
                        {required: true, message: "", trigger: "blur"},
                    ],
                    tag: [
                        {required: true, message: "", trigger: "blur"},
                    ],
                    introduce: [
                        {required: true, message: "", trigger: "blur"},
                    ],
                },
                dynamicTags: [],
                inputVisible: false,
                inputValue: '',
                imgHideUpload1: false, // 控制上传图片显示
                imgHideUpload2: false, // 控制上传图片显示
                form: {
                    id: '',
                    name: '',
                    img: '',
                    photo: '',
                    introduce: '',
                    tag: [],
                    token: getToken()
                },
                openDialog: false,
                type: 0,
                params: {
                    key: '',
                    token: getToken(),
                    page: 1,
                    per_page: 10
                },
                list: [],
                total: 0
            }
        },
        mounted() {
            this.initData()
        },
        computed: {
            ...mapGetters(["isLogin"]),
        },
        methods: {
            clearPhoto(){
                this.$confirm('请确认是否清除形象照?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.form.photo = ''
                }).catch(() => {
                });
            },
            delInfo(item){
                this.$confirm('请确认是否删除该老师?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const params = {
                        id: item.id,
                        token:getToken()
                    }
                    delTeacher(params).then(res=>{
                        if(res.status == 200){
                            this.$message.success('删除成功')
                            this.close()
                            this.initData()
                            this.openDialog = false
                        }
                    })
                }).catch(() => {
                });
            },
            close(){
                this.form.id = ''
                this.form.name = ''
                this.form.img = ''
                this.form.introduce = ''
                this.form.photo = ''
                this.form.tag = []
            },
            submit() {
                if (!this.isLogin) {
                    this.$login()
                    return
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let params = JSON.parse(JSON.stringify(this.form))
                        params.tag = params.tag.join(',')
                        if(this.type === 1){
                            delete params.id
                            addTeacher(params).then(res=>{
                                if(res.status === '200'){
                                    this.$message.success('添加成功')
                                    this.$refs.form.resetFields()
                                    this.openDialog = false
                                    this.initData()
                                }
                            })
                        }else{
                            editTeacher(params).then(res=>{
                                if(res.status === '200'){
                                    this.$message.success('编辑成功')
                                    this.$refs.form.resetFields()
                                    this.openDialog = false
                                    this.initData()
                                }
                            })
                        }
                    }
                });
            },
            handleClose(tag) {
                this.form.tag.splice(this.form.tag.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            handleInputConfirm() {
                if (this.form.tag.length == 5) {
                    this.$message.warning('标签最多输入五个')
                    return
                }
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.tag.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            handleAvatarSuccess1(res) {
                if (res.data.message == "uploadSuccess") {
                    this.form.img = res.data.filePath;
                }
            },
            //删除图片
            removeImgs1(file, fileList) {
                this.imgHideUpload1 = fileList.length >= 1;
                //从表单对象中删除
                this.form.img = this.form.img.filter(function (item) {
                    if (file.response) {
                        return item != file.response.data.filePath;
                    }
                    if (file.url) {
                        return item != file.url;
                    }
                });
            },

            handleAvatarSuccess2(res) {
                if (res.data.message == "uploadSuccess") {
                    this.form.photo = res.data.filePath;
                }
            },
            //删除图片
            removeImgs2(file, fileList) {
                this.imgHideUpload2 = fileList.length >= 1;
                //从表单对象中删除
                this.form.photo = this.form.photo.filter(function (item) {
                    if (file.response) {
                        return item != file.response.data.filePath;
                    }
                    if (file.url) {
                        return item != file.url;
                    }
                });
            },

            open(val) {
                if (val) {
                    this.type = 2
                    this.form = JSON.parse(JSON.stringify(val))
                    this.form.tag = this.form.tag.split(',')
                } else {
                    this.type = 1
                }
                this.form.token = getToken()
                this.openDialog = true
            },
            initData() {
                getTeacherList(this.params).then(res => {
                    if (res.status === '200') {
                        this.list = res.data.data
                        this.total = res.data.total
                    }
                })
            },
            handleSizeChange(val) {
                this.params.per_page = val
                this.params.page = 1
                this.initData()
            },
            handleCurrentChange(val) {
                this.params.page = val
                this.initData()
            },
        }
    }
</script>

<style scoped>
    .teacherListBox {
        position: relative;
        border-radius: 0.06rem;
        overflow: hidden;
        background: #F7F6FB;
        padding: 0.2rem 0.3rem 0;
        min-height: 8rem;
        width: 11rem;
        margin-bottom: 0.3rem;
    }

    .headBox {
        width: 100%;
        height: 0.6rem;
    }

    .headBox >>> .el-input__inner {
        border: none
    }

    ::v-deep .el-table__body {
        -webkit-border-vertical-spacing: 10px;
    }

    ::v-deep .el-table, .el-table__expanded-cell {
        background-color: transparent
    }

    ::v-deep .el-table::before {
        height: 0;
    }

    ::v-deep .el-table th.gutter {
        background: #000E26 !important;
    }

    .cardBox {
        width: 5.78rem;
        /*height: 0.37rem;*/
        opacity: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .cardBox > div {
        text-align: center;
        /*vertical-align: center;*/
        border-radius: 0.08rem;
        padding: 0.02rem 0.12rem 0.02rem 0.12rem;
        border: 1px solid rgba(229, 229, 229, 1);
        /*color: rgba(229, 229, 229, 1);*/
        margin-right: 0.06rem;
        font-size: 0.14rem;
        background: rgba(242, 242, 242, 1);
        /*width:2rem;*/
        margin-bottom:0.12rem
    }

    .left {
        width: 40%;
        /*height:6rem;*/
    }

    .right {
        width: 60%;
        /*height:6rem*/
    }

    .avatar1 {
        height: 1.6rem;
        width: 1.6rem;
    }

    .avatar-uploader1 {
        height: 1.6rem;
        width: 1.6rem;
    }

    .imgHide1 >>> .el-upload--picture-card {
        display: none !important;
    }

    .avatar2 {
        height: 2.025rem;
        width: 1.6rem;
    }

    .avatar-uploader2 {
        height: 2.025rem;
        width: 1.6rem;
    }

    .imgHide2 >>> .el-upload--picture-card {
        display: none !important;
    }

    ::v-deep .img .el-upload--picture-card {
        width: 1.6rem;
        height: 1.6rem;
        /*border: none !important;*/
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .photo .el-icon-close{
        font-size: 0.2rem;
        color:red;
        position: absolute;
        left: 1.6rem;
        top:0rem;
        cursor: pointer;
    }
    ::v-deep .photo .el-upload--picture-card {
        width: 1.6rem;
        height: 2.025rem;
        /*border: 1px solid gray !important;*/
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .tagBox {
        height: 1.4rem;
        overflow-y: auto;
        border: 1px solid #EBECED;
        padding: 0.08rem
    }

    >>> .el-empty{
        width:100%
    }
    .fblBox{
        font-size: 0.12rem;
    }
</style>
